import React, { Component, Fragment } from 'react'
import PractitionerList from './PractitionerList'
import LocSearch from '../containers/LocSearch'
import SearchClose from './SearchClose' 
import Map from '../components/Map'

import PractitionerDialog from './PractitionerDialog'


import { Paper, Grid } from '@material-ui/core' 
import withWidth from '@material-ui/core/withWidth';

import '../style/App.css'

const Style = {
  Utils: {
    position: 'absolute',
    zIndex: 10
  },
  AdressSearch: {
    top: 0,
    left: 0
  },
  closeSearch: {
    width: '230px',
    left: '37.5vw',
    top: '4vw',
    marginLeft: '-115px',
    position: 'absolute',
    zIndex: 10
  }
}

const LocSearchStyle = (width) => ({
  Paper: {
    paddingTop: '20px',
    top: width === 'xs' ? '0' : '2vh',
    left: width === 'xs' ? '0' : '2vw'
  },
  InputBase: {
    width: '70%'
  }
})


class MapPannel extends Component {
  componentDidMount() {
    const paramId = this.props.match.params.id
    if (paramId === undefined) {
      this.props.fetchClosePractitioner(this.props.currentLocation)
    } else 
      this.props.fetchSelectedPractitioner(paramId)
  }

  render() {
    const selectedPractitioner = (practitioners, selectedPractitionerId) => {
      return practitioners.find((practitioner) => practitioner.id === selectedPractitionerId)
    }

    return (
    <Fragment>
      <PractitionerDialog 
        practitioner={selectedPractitioner(this.props.practitioners, this.props.selectedPractitionerId) || {}}
        open={this.props.showDialog}
        closeModal={this.props.closeModal}
      />
      <SearchClose onClick={() => this.props.fetchClosePractitioner(this.props.currentCenter, 0, true)} />
      <Grid container>
        <Grid item xs={12}>
          <LocSearch style={LocSearchStyle(this.props.width)}/>
        </Grid>
        <Grid item sm={9} xs={12}>
          <Map onChange={this.props.onMapChange}
            location={this.props.currentLocation}
            practitioners={this.props.practitioners}
            selectedPractitionerId={this.props.selectedPractitionerId}
            setSelectedPractitioner={this.props.setSelectedPractitioner}
            practitionerHover={this.props.practitionerHover}
          />
        </Grid>
        <Grid item sm={3} xs={true}>
          <PractitionerList
            practitioners={this.props.practitioners}
            fetchClosePractitioner={this.props.fetchClosePractitioner}
            selectedPractitioner={this.props.selectedPractitionerId}
            setSelectedPractitioner={this.props.setSelectedPractitioner}
            setHoverPractitioner={this.props.setHoverPractitioner}
            onClickMore={this.props.onClickMore}
            location={this.props.currentLocation}
            page={this.props.page}
          />
        </Grid>
      </Grid>
    </Fragment>)
  }
}

export default withWidth()(MapPannel)