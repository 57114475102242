import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LocSearch from '../components/LocSearch'

import { fetchClosePractitioner, error } from '../actions'

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchClosePractitionerAdress: (latLng) => {
      dispatch(fetchClosePractitioner(latLng))
    },
    error: (text) => { dispatch(error(text)) },
    fetchClosePractitionerGeolocation: () => {
    // TODO; button geolocation
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
            dispatch(fetchClosePractitioner({
              lat: position.coords.latitude,
              lng: position.coords.longitude
          }))
        })
      } else {
        dispatch(error('Géolocalisation impossible'))
      }
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocSearch))