import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchClosePractitioner, updateCurrentCenter, setSelectedPractitioner, fetchSelectedPractitioner, setHoverPractitioner } from '../actions'
import MapPannel from '../components/MapPannel'

const mapStateToProps = (state, ownProps) => {
  return {
    currentLocation: state.position,
    practitioners: state.doc,
    selectedPractitionerId: state.selectedDocId,
    currentCenter: state.currentCenter,
    page: state.page,
    showDialog: state.showDialog,
    practitionerHover: state.hoverDocId
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      onMapChange: (center) => {
        dispatch(updateCurrentCenter(center.center))
      },
      setSelectedPractitioner: (id) => {
        dispatch(setSelectedPractitioner(id, true))
        ownProps.history.push('/map/' + id)
      },
      setHoverPractitioner: (id) => {
        dispatch(setHoverPractitioner(id))
      },
      fetchClosePractitioner: (loc) => {
        dispatch(fetchClosePractitioner(loc))
      },
      onClickMore: (loc, page) => (e) => {
        dispatch(fetchClosePractitioner(loc, page))
      },
      fetchSelectedPractitioner: (id) => {
        dispatch(fetchSelectedPractitioner(id))
      },
      closeModal: () => {
        dispatch({
          type: 'CLOSE_MODAL'
        })
      }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MapPannel))
