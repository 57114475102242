import React from 'react'
// import { Paper, Typography, Divider } from '@material-ui/core'

import circleMarker from '../icons/circle-marker.svg'

import _ from 'lodash'


// const Style = {
//     Paper: {
//         minHeight: '10vh',
//         minWidth: '20vw',
//         marginTop: '-15vh',
//         marginLeft: '-10vw',
//         backgroundColor: '#D0F4EA',
//         padding: '5px',
//     }
// }

const capi = txt => _.startCase(_.toLower(txt))

// const SelectedPractitionerMarker = ({ practitioner }) => {
//     return (
//         <Fragment>
//             <svg height="30" width="30" style={{
//                     position: 'absolute',
//                     top: -15,
//                     left: -15
//                 }}>
//                 {/* <filter id="blurMe">
//                     <feGaussianBlur in="SourceGraphic" stdDeviation="2"/>
//                 </filter> */}
//                     {/* <circle cx="15" cy="15" r="5" fill="#000" filter="url(#blurMe)"  /> */}
//                     <circle cx="15" cy="15" r="6" fill="#D0F4EA" />
//             </svg>
//             <Paper style={Style.Paper}>
//                 <Typography gutterBottom>
//                     <strong>{capi(practitioner.fullname)}</strong>
//                     {practitioner.carte_vitale && <img style={{height:'12px',width:'12px', marginLeft: '5px'}} src={vitalIcon}/>}
//                     {practitioner.conventionne && <img style={{height:'12px',width:'12px', marginLeft: '5px'}} src={convIcon}/>}
//                 </Typography>
//                 <Divider />
//                 <Typography >
//                     <b>Adresse: </b> {capi(practitioner.address1)} {capi(practitioner.address2)} {capi(practitioner.address3)}
//                 </Typography>
//                 <Typography>
//                     <b>Téléphone: </b> {practitioner.tel}
//                 </Typography>
//                 <Typography >
//                     <a href='#' >Je ne veux pas que mon nom figure ici </a>
//                 </Typography>
//             </Paper>            
//         </Fragment>
//     )
// }


const SelectedPractitionerMarker = () => {
    return (
        <img style={{
            position: 'absolute',
            top: '-5',
            left: '-5',
            height: '10',
            width: '10'
        }} src={circleMarker} ></img>
    )
}

export default SelectedPractitionerMarker

{/* <path fill="#C8E6E8" d="M0 0 L10 10 L20 0 Z" /> */}