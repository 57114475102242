import React, { Fragment } from 'react'
import GoogleMapReact from 'google-map-react';

import { PractitionerMarker, PositionMarker } from './PractitionerMarker'
import SelectedPractitionerMarker from './SelectedPractitionerMarker'

const Map = ({onChange, location, practitioners, selectedPractitionerId, setSelectedPractitioner, practitionerHover}) => {

  let selectedPractitioner = practitioners.find((practitioner) => practitioner.id === selectedPractitionerId)
  let otherPractitioners = practitioners.filter((practitioner) => practitioner.id !== selectedPractitionerId)

  return (
    <Fragment>
      <GoogleMapReact
        // bootstrapURLKeys={{ key: 'AIzaSyBJnes30JkXj6zo1iH73oIhUUKw7Bt1AE8' }}
        center={location}
        defaultZoom={17}
        onChange={onChange}
        options={{fullscreenControl: false}}
      >
      {/* <PositionMarker 
        lat={location.lat}
        lng={location.lng}
      /> */}
      {otherPractitioners.map((practitioner) => {
          return (<PractitionerMarker
              onClick={() => setSelectedPractitioner(practitioner.id)}
              hover={practitionerHover === practitioner.id}
              key={practitioner.id}
              lat={practitioner.latitude}
              lng={practitioner.longitude}
            />)
        }
      )}
      {selectedPractitioner ? <SelectedPractitionerMarker 
        practitioner={selectedPractitioner} 
        lat={selectedPractitioner.latitude}
        lng={selectedPractitioner.longitude}
      /> : null}
      </GoogleMapReact>
    </Fragment>
  );
}

export default Map