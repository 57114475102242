import React from 'react';
import '../style/App.css';
import Input from '@material-ui/core/Input';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import gpsIcon from '../icons/baseline-gps.svg';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Paper } from '@material-ui/core';

import withWidth from '@material-ui/core/withWidth';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


// search options for places autocomplete
const searchOptions = {
  types: ["geocode"],
  componentRestreictions: { country: "fr" }
}

class AdressSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  handleClickGeolocation = e => {
    e.preventDefault();
    this.props.fetchClosePractitionerGeolocation()
    if (this.props.location.pathname == '/') {
      this.props.history.push('/map')
    }
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.fetchClosePractitionerAdress(latLng)
        this.props.history.push('/map')
      })
      .catch(error => this.props.error('Impossible de trouver le lieu'));
  };

  render() {
    return (
      <Paper style={{
        display: 'inline-block',
        position: this.props.width === 'xs' ? 'relative' : 'absolute',
        width: this.props.width === 'xs' ? '80vw' : 'auto',
        // top: this.props.width === 'xs' ? '0' : 'auto',
        // left: this.props.width === 'xs' ? '0' : 'auto',
        padding: this.props.width === 'xs' ? '10vw': '10px',
        zIndex: 10,
        ...this.props.style.Paper
      }}>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={searchOptions}
          className='loc-search'
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <InputBase 
                {...getInputProps({
                  placeholder: 'Rechercher Adresse ...',
                  className: 'location-search-input',
                  variant: 'filled',
                  style: this.props.style.InputBase
                })}
              />
              <IconButton className='location-search-geolocation' onClick={this.handleClickGeolocation}><img src={gpsIcon} /></IconButton>
              <div className="autocomplete-dropdown-container">
              <List component="nav">
                {loading && <div>Chargement...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#ee737f', cursor: 'pointer' }
                    : { backgroundColor: '#FFFFFF', cursor: 'pointer' };
                  return (
                    <ListItem button
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </ListItem>
                  );
                })}
                </List>
              </div>
              
            </div>
          )}
        </PlacesAutocomplete>
      </Paper>
    );
  }
}
export default withWidth()(AdressSearch)
