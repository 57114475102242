import {SET_SELECTED_PRACTITIONER, GET_CLOSE_SUCCESS, ERROR, UPDATE_CURRENT_CENTER} from '../actions'
import { getDistance } from 'geolib';

const calcDistance = (practitioners, center) => {
    return practitioners.map((practitioner) => {
        return {
            ...practitioner,
            distance: getDistance({latitude: practitioner.latitude, longitude: practitioner.longitude}, {latitude: center.lat, longitude: center.lng})
        }
    })
}

export default function productReducer(state = {}, action) {
    switch (action.type) {
        case ERROR:
            return { ...state, error: action.payload.error }
        case UPDATE_CURRENT_CENTER:
            return { ...state,
                currentCenter: {
                    lat: action.payload.lat,
                    lng: action.payload.lng
                }
            }
        case SET_SELECTED_PRACTITIONER:
            return { ...state, 
                selectedDocId: action.payload.id,
                showDialog: action.payload.showDialog
            }
        case 'SET_HOVER_PRACTITIONER':
            return { ...state, 
                hoverDocId: action.payload.id
            }
        case 'CLOSE_MODAL':
            return { ...state, 
                showDialog: false
            }
        case GET_CLOSE_SUCCESS:
            return { ...state, 
                // position: {
                //     lat: action.payload.lat,
                //     lng: action.payload.lng,
                //     zoom: state.position.zoom,
                // }, 
                doc: [...calcDistance(action.payload.docs, action.payload.pos).sort((a, b) => a.distance > b.distance ? 1 : -1)]
                // selectedDocId: action.payload.resetSelected ? '' : state.selectedDocId,
                // page: JSON.parse(action.payload.page)
            }
        default:
            return state
    }
}