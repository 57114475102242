import React, { Component } from 'react'
import PractitionerSearch from '../containers/PractitionerSearch'
import PractitionerElement from './PractitionerElement'
import { Button, Paper, List } from '@material-ui/core';

const Style = {
    Paper: {
        height: '100vh',
        width: '100%',
        overflow: 'auto',
        borderRadius: 0,
        color: '#412023'
    }
}

const PractitionerList = ({practitioners, selectedPractitioner, setSelectedPractitioner, setHoverPractitioner, onClickMore, page, location}) => {
    return (
    <Paper style={Style.Paper}>
        <PractitionerSearch/>
        {/* <Paper> */}
            <List>
                {practitioners.map((d) => {
                return (<PractitionerElement 
                        key={d.id} 
                        doc={d}
                        onChangeSelectedDoc={(id) => {setSelectedPractitioner(id, true)}}
                        onHoverPractitioner={(id) => {setHoverPractitioner(id)}}
                        selected={d.id === selectedPractitioner} 
                    />)
                })}
            </List>
        {/* </Paper> */}
        {practitioners.length > 24 ?
            (<Button onClick={onClickMore(location, page + 1)} style={{marginLeft: 'auto'}}>
                PLUS
            </Button>) : ''
        }
    </Paper>)
}

export default PractitionerList