import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './reducers'
import thunk from "redux-thunk"
import { logger } from 'redux-logger'
import { BrowserRouter as Router, Route } from "react-router-dom";

import './style/index.css';

import MapPannel from './containers/MapPannel';
import Home from './components/Home';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

// theme material iu

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#545F66',
    },
    secondary: {
      main: '#D9E6EE'
    },
    text: {
      main: '#545F66'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Montserrat", sans-serif'
  }
});

const initialState = {
    position: {
        lat: 48.864716,
        lng: 2.349014
    },
    doc: [],
    zoom: 11,
    page: 0,
    selectedDocId: null,
    hoverDocId: null,
    showDialog: false
};

const store = createStore(rootReducer, initialState, applyMiddleware(thunk, logger))

render(
  <Provider store={store}>
    <Router>
      <MuiThemeProvider theme={theme}>
        <div style={{height: '100vh'}}>
            <Route exact path="/" component={Home} />
            <Route path="/map/:id?" component={MapPannel} />
        </div>
      </MuiThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
)
