import { connect } from 'react-redux'
import PractitionerSearch from '../components/PractitionerSearch'

import { fetchTextPractitioner, fetchClosePractitioner, error } from '../actions'

const mapStateToProps = (state, ownProps) => {
  return {
    currentPos: state.position
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchTextPractitioner: (text, loc) => {
            dispatch(fetchTextPractitioner(text, loc))
        },
        fetchClosePractitioner: (loc) => {
            dispatch(fetchClosePractitioner(loc))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PractitionerSearch)