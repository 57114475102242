import React from 'react'
import {Button, Paper, withWidth} from '@material-ui/core'


const Style = {
  Button: {
    margin: 0,
    padding: 0,
    width: '230px',
    left: '37.5vw',
    top: '4vw',
    marginLeft: '-115px',
    position: 'absolute',
    zIndex: 10
  }
}

const SearchClose = ({ onClick, width }) => {
  return (
      <Button variant="outlined" color="primary" onClick={onClick} style={{
        ...Style.Button,
        display: width === 'xs' ? 'none' : 'block'
      }}>
      Rechercher dans la zone
    </Button>)
}

export default withWidth()(SearchClose);
