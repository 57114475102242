import axios from 'axios'

export const FETCH_CLOSE_PRACTITIONER = 'FETCH_CLOSE_PRACTITIONER'
export const GET_CLOSE_BEGIN = 'GET_CLOSE_BEGIN'
export const GET_CLOSE_FAILURE = 'GET_CLOSE_FAILURE'
export const GET_CLOSE_SUCCESS = 'GET_CLOSE_SUCCESS'
export const ERROR = 'ERROR'
export const UPDATE_CURRENT_CENTER = 'UPDATE_CURRENT_CENTER' 
export const SET_SELECTED_PRACTITIONER = 'SET_SELECTED_PRACTITIONER' 

export const error = (text) => {
    return {
        type: ERROR,
        payload: {
            error: text
        } 
    }
}

export const closeModal = () => ({
        type: 'CLOSE_MODAL'
})

export const setSelectedPractitioner = (id, showDialog = false) => {
    return {
        type: SET_SELECTED_PRACTITIONER,
        payload: {
            id,
            showDialog
        }
    }
}

export const setHoverPractitioner = (id) => {
    return {
        type: 'SET_HOVER_PRACTITIONER',
        payload: {
            id
        }
    }
}

export const updateCurrentCenter = (loc) => {
    return {
        type: UPDATE_CURRENT_CENTER,
        payload: {
            lat: loc.lat,
            lng: loc.lng
        }
    }
}

export const fetchSelectedPractitioner = (id) => (dispatch) => {
    dispatch(fetchPractitionerBegin())
    axios.get('https://staging.api.annuaire-orthodontistes.fr/practitioner/', {
        params: {
            id: id
        }
    })
        .then(function (response) {
            console.log(response.data[0])
            let practitioner = response.data[0]
            let location = {
                lat: practitioner.latitude,
                lng: practitioner.longitude
            }
            dispatch(fetchClosePractitioner(location, 0, false))
            dispatch(setSelectedPractitioner(id))
        })
        .catch(function (error) {
            dispatch(fetchPractitionerFailure(error))
        })
}

export const fetchClosePractitioner = (pos, page = 0, resetSelected = true) => (dispatch) => {
    dispatch(fetchPractitionerBegin())
    axios.get('https://staging.api.annuaire-orthodontistes.fr/practitioner/find-by-location', {
        params: {
            latitude: pos.lat,
            longitude: pos.lng
        }
    })
    .then(function (response) {
        dispatch(fetchPractitionerSuccess(response.data, pos, resetSelected))
    })
    .catch(function (error) {
        dispatch(fetchPractitionerFailure(error))
    })
}

export const fetchTextPractitioner = (text, pos) => (dispatch) => {
    dispatch(fetchPractitionerBegin())
    axios.get('http://localhost:1337/practitioner/text', {
        params: {
            text: text,
            loc: pos
        }
    })
    .then(function (response) {
        dispatch(fetchPractitionerSuccess(response.data, pos))
    })
    .catch(function (error) {
        dispatch(fetchPractitionerFailure(error))
    })
}

const fetchPractitionerBegin = () => {
    return {
        type: GET_CLOSE_BEGIN,
        payload: {
            isLoading: true
        } 
    }
}

const fetchPractitionerFailure = (error) => {
    return {
        type: GET_CLOSE_FAILURE,
        payload: {
            error: error,
            isLoading: false
        }
    }
}

const fetchPractitionerSuccess = (data, loc, resetSelected = true) => {
    return {
        type: GET_CLOSE_SUCCESS,
        payload: {
            // isLoading: false,
            // page: data.page,
            docs: data,
            pos: loc,
            // lng: loc.lng
        }
    }
}
