import React from 'react';
import LocSearch from '../containers/LocSearch';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';

import '../style/App.css'

const Home = ({ width }) => {
  return (
    <div className='home' style={{
      padding: width == 'xs' ? '2vw' : '10vw',
    }}>
      <Typography align='center' color='inherit' variant='h1' style={{
        color: 'white',
        fontSize: '8vw'
      }}>
        annuaire-orthodontistes.fr
      </Typography>
      <div style={{
        textAlign: 'center'
      }}>
        <LocSearch style={{
          Paper : {
            width: width == 'xs' ? '60vw' : '60vw',
            // marginLeft: '20vw',
            position: 'relative',
            marginTop: '10vh',
            paddingTop: '20px'
          },
          InputBase: {
            width: '80%'
          }
        }} />
      </div>
    </div>
  )
}

export default withWidth()(Home);