import React, { Component } from 'react'

import circleMarkerWhite from '../icons/circle-marker-white.svg'
import circleMarkerHover from '../icons/circle-marker-hover.svg'

const style = {
  cursor: 'pointer',
  position: 'absolute',
  top: '-7.5',
  left: '-7.5',
  height: '15',
  width: '15'
}

export const PractitionerMarker = ({hover, onClick}) => {
  if (hover) 
    return (<img style={style} src={circleMarkerHover} ></img>)
  return (<img style={style} src={circleMarkerWhite} ></img>)
}

export const PositionMarker = ({ text }) => <svg height="10" width="10" style={{
  marginTop: '-5px',
  marginLeft: '-5px'
}}>
  <circle cx="5" cy="5" r="5" fill="#dddddd" />
  <circle cx="5" cy="5" r="4" fill="#60a3bc" />
  Sorry, your browser does not support inline SVG.  
</svg>;
