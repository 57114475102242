import React, { Component } from 'react'
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import { Paper } from '@material-ui/core';
import SearchIcon from '../icons/baseline-search.svg'


const Style = {
    Paper: {
        padding: 10,
        position: 'sticky'
    },
    Input: {
        width: '100% - 30px'
    }
}

class PractitionerSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            query: ''
        }
        this.onChangeHandler = this.onChangeHandler.bind(this)
        this.onClickHandler = this.onClickHandler.bind(this)
    }

    onClickHandler() {
        this.props.fetchTextPractitioner(this.state.query, this.props.currentPos)
    }
    
    onChangeHandler(e) {
        this.setState({
            query: e.target.value
        }, () => {
            if (this.state.query && this.state.query.length > 1) {
                if (this.state.query.length % 2 === 0) {
                    this.props.fetchTextPractitioner(this.state.query, this.props.currentPos)
                }
            } else if (this.state.query.length == 0) {
                this.props.fetchClosePractitioner(this.props.currentPos)
            }
        })
    }

    render() {
        return (
            <div style={Style.Paper}>
                <Input type="text" name="doc-input" onChange={this.onChangeHandler} style={Style.Input}></Input>
                <IconButton onClick={this.onClickHandler}><img src={SearchIcon} style={{ width: '24px' }}/></IconButton>
            </div>
        );
    }
}

export default PractitionerSearch