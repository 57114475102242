import React, { Fragment } from 'react'
import { Dialog, Button } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import vitalIcon from '../icons/vital.svg'
import convIcon from '../icons/conv.svg'
import _ from 'lodash'

const capi = txt => _.startCase(_.toLower(txt))

// const addressFormat = (address, address2, address3, postalCode) => {
    
//     return
// }

const PractitionerDialog = ({ closeModal, practitioner, open}) =>{
    return (
        <div style={{
            zIndex: 150
        }}>
            <Dialog
                open={open}
                onClose={closeModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                            {capi(practitioner.firstName + ' ' + practitioner.lastName)}
                            {practitioner.carte_vitale && <img style={{height:'12px',width:'12px', marginLeft: '5px'}} src={vitalIcon} alt='vital-icon'/>}
                            {practitioner.conventionne && <img style={{height:'12px',width:'12px', marginLeft: '5px'}} src={convIcon} alt='conv-icon' />}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                            <b>Adresse: </b> {practitioner.address + ' '} 
                            {practitioner.postalCode + ' ' + practitioner.city}<br />
                            {practitioner.tel !== "null" ? <Fragment><b>Téléphone: </b>{practitioner.telephone}<br /></Fragment> : ''} 
                            <a href='#' >Je ne veux pas que mon nom figure ici </a><br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default PractitionerDialog