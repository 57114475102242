import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash'

import vitalIcon from '../icons/vital.svg'
import convIcon from '../icons/conv.svg'

import { ListItem, ListItemText } from '@material-ui/core';

class PractitionerElement extends Component {
    render() {
      return (
        <ListItem 
          button
          selected={this.props.selected}
          onClick={(event) => this.props.onChangeSelectedDoc(this.props.doc.id)}
          onMouseEnter={(event) => this.props.onHoverPractitioner(this.props.doc.id)}
        >
          <ListItemText>
            <Typography >{_.startCase(_.toLower(this.props.doc.firstName + ' ' + this.props.doc.lastName))}
              {this.props.doc.carte_vitale ? <img style={{height:'12px',width:'12px', marginLeft: '5px'}} src={vitalIcon}/> : ''}
              {this.props.doc.conventionne ? <img style={{height:'12px',width:'12px', marginLeft: '5px'}} src={convIcon}/> : ''}
              ({this.props.doc.distance/1000} km)
            </Typography>
          </ListItemText>
        </ListItem>
      )
    }
}

export default PractitionerElement